import React from "react"
import IconTwitter from "./IconTwitter"
import IconFacebook from "./IconFacebook"
import IconInstagram from "./IconInstagram"
import IconEmail from "./IconEmail"
import IconLinkedIn from "./IconLinkedIn"

const selectSocialIcon = (id, fill) => {
  switch (id.toLowerCase()) {
    case "twitter":
      return <IconTwitter fill={fill} />
    case "facebook":
      return <IconFacebook fill={fill} />
    case "instagram":
      return <IconInstagram fill={fill} />
    case "linkedin":
      return <IconLinkedIn fill={fill} />
    case "email":
      return <IconEmail fill={fill} />
    default:
      break
  }
}

export default selectSocialIcon
