import React from "react"
export default function ({ formLink }) {
  return (
    <div className="formWrapper">
      <link
        rel="preload"
        href="https://d3rse9xjbp8270.cloudfront.net/at.js"
        as="script"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        href="https://d3rse9xjbp8270.cloudfront.net/at.min.css"
        as="style"
      />
      <script
        type="text/javascript"
        src="https://d3rse9xjbp8270.cloudfront.net/at.js"
        crossOrigin="anonymous"
      ></script>
      <iframe src={`https://secure.everyaction.com/${formLink}`}></iframe>
      {/* <div
        className="ngp-form"
        data-form-url={`https://secure.everyaction.com/v1/Forms/${formLink}`}
        data-fastaction-endpoint="https://fastaction.ngpvan.com"
        data-inline-errors="true"
        data-endpoint="https://api.myngp.com"
        data-fastaction-nologin="true"
        data-databag-endpoint="https://profile.ngpvan.com"
        data-databag="everybody"
        data-mobile-autofocus="false"
      ></div> */}
    </div>
  )
}
