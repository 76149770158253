import * as React from "react"

export default function IconLinkedIn({ fill }) {
  return (
    <svg
      width="31"
      height="31"
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.6787 30.8394C23.963 30.8394 30.6787 
24.1236 30.6787 15.8394C30.6787 7.55508 23.963 0.839355 15.6787 0.839355C7.39444 0.839355 
0.678711 7.55508 0.678711 15.8394C0.678711 24.1236 7.39444 30.8394 15.6787 30.8394ZM9.67871 
21.4011V12.9678H12.4106V21.4011H9.67871ZM9.67871 10.296C9.67871 9.46915 10.2897 8.83936 11.2242 
8.83936C12.1588 8.83936 12.7343 9.46915 12.752 10.296C12.752 11.1058 12.1588 11.7537 11.2062 
11.7537H11.1888C10.2716 11.7537 9.67871 11.1058 9.67871 10.296ZM16.8309 14.3606C17.1935 13.7864 
17.8429 12.9678 19.2933 12.9678C21.091 12.9678 22.4387 14.1734 22.4387 16.7637L22.4386 
21.5991H19.7069V17.0873C19.7069 15.9541 19.3115 15.1807 18.3226 15.1807C17.5679 15.1807 17.1183 
15.7021 16.9209 16.2056C16.8487 16.386 16.8309 16.6375 16.8309 16.8895V21.5994H14.0988C14.0988 
21.5994 14.1348 13.9574 14.0988 13.1661H16.8309V14.3606Z"
        fill={fill}
      />
    </svg>
  )
}
