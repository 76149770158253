import * as React from "react"

export default function IconInstagram({ fill }) {
  return (
    <svg
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.4014 3.41666H11.5983C7.38018 3.41666 3.9165 6.88033 3.9165 11.0985V28.9015C3.9165 33.1197 7.38018 36.5833 11.5983 36.5833H29.4014C33.6195 36.5833 37.0832 33.1197 37.0832 28.9015V11.0985C37.0832 6.88033 33.6195 3.41666 29.4014 3.41666ZM11.5983 0.416656H29.4014C35.2764 0.416656 40.0832 5.22347 40.0832 11.0985V28.9015C40.0832 34.7765 35.2764 39.5833 29.4014 39.5833H11.5983C5.72332 39.5833 0.916504 34.7765 0.916504 28.9015V11.0985C0.916504 5.22347 5.72332 0.416656 11.5983 0.416656ZM20.4998 26.7917C24.2508 26.7917 27.2915 23.7509 27.2915 20C27.2915 16.2491 24.2508 13.2083 20.4998 13.2083C16.7489 13.2083 13.7082 16.2491 13.7082 20C13.7082 23.7509 16.7489 26.7917 20.4998 26.7917ZM20.4998 29.7917C25.9076 29.7917 30.2915 25.4078 30.2915 20C30.2915 14.5922 25.9076 10.2083 20.4998 10.2083C15.092 10.2083 10.7082 14.5922 10.7082 20C10.7082 25.4078 15.092 29.7917 20.4998 29.7917ZM30.2915 10.2083C31.3731 10.2083 32.2498 9.33155 32.2498 8.24999C32.2498 7.16843 31.3731 6.29166 30.2915 6.29166C29.2099 6.29166 28.3332 7.16843 28.3332 8.24999C28.3332 9.33155 29.2099 10.2083 30.2915 10.2083Z"
        fill={fill}
      />
    </svg>
  )
}
