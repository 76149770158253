import * as React from "react"

export default function IconFacebook({ fill }) {
  return (
    <svg
      width="42"
      height="42"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M39.1178 0.75H2.88221C2.32901 0.787356 1.80811 1.02399 1.41605 1.41605C1.02399 1.80811 0.787356 2.32901 0.75 2.88221L0.75 39.1178C0.753128 39.6823 0.978774 40.2228 1.37796 40.622C1.77715 41.0212 2.31767 41.2469 2.88221 41.25H22.2793V25.4764H17.162V19.5086H22.4914V15.0322C22.3765 13.9587 22.4998 12.873 22.8525 11.8526C23.2052 10.8321 23.7786 9.90203 24.5318 9.12853C25.2851 8.35503 26.1997 7.75717 27.2104 7.37754C28.2211 6.99791 29.3031 6.84586 30.3793 6.93221C32.5115 6.93221 34.4293 7.14424 35.0678 7.14424V12.688H31.8707C29.312 12.688 28.8856 13.9673 28.8856 15.6731V19.5086H34.8534L34.0005 25.6909H28.6736V41.25H39.1178C39.6823 41.2469 40.2228 41.0212 40.622 40.622C41.0212 40.2228 41.2469 39.6823 41.25 39.1178V2.88221C41.2126 2.32901 40.976 1.80811 40.5839 1.41605C40.1919 1.02399 39.671 0.787356 39.1178 0.75V0.75Z"
        fill={fill}
      />
    </svg>
  )
}
