import * as React from "react"

export default function IconEmail({ fill }) {
  return (
    <svg
      width="31"
      height="31"
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.0863 5.04817C23.1148 2.19915 19.2128 0.699659 15.1007 0.849608C7.32666 1.14951 0.993389 7.47734 0.693234 15.2447C0.513141 19.3833 2.01392 23.2819 4.86539 26.2509C7.71686 29.2199 11.5588 30.8394 15.671 30.8394V27.8404C12.3693 27.8404 9.30768 26.5208 
    7.0265 24.1516C4.74533 21.7824 3.54471 18.6635 3.66477 15.3646C3.90489 9.15677 8.97751 
    4.0885 15.1907 3.84858C18.4924 3.72862 21.614 4.89822 23.9853 7.20743C26.3565 9.48665 
    27.6772 12.5456 27.6772 15.8445V18.0937C27.6772 19.3233 26.6566 20.3429 25.426 20.3429C24.1954 
    20.3429 23.1748 19.3233 23.1748 18.0937V15.8445C23.1748 11.7059 19.8131 8.34704 15.671 
    8.34704C11.5288 8.34704 8.16709 11.7059 8.16709 15.8445C8.16709 19.9831 11.5288 23.3419 15.671 
    23.3419C17.8021 23.3419 19.7231 22.4422 21.0738 21.0327C22.0343 22.4122 23.6251 23.3419 25.426 
    23.3419C28.3075 23.3419 30.6787 20.9727 30.6787 18.0937V15.8445C30.6787 11.7359 29.0579 7.8972 
    26.0863 5.04817ZM15.671 20.3429C13.1797 20.3429 11.1686 18.3336 11.1686 15.8445C11.1686 13.3553 
    13.1797 11.346 15.671 11.346C18.1623 11.346 20.1733 13.3553 20.1733 15.8445C20.1733 18.3336 
    18.1623 20.3429 15.671 20.3429Z"
        fill={fill}
      />
    </svg>
  )
}
