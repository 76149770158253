import * as React from "react"
import { graphql } from "gatsby"

import Seo from "../components/seo"
import Layout from "../components/layout"
import Footer from "../components/Footer"
import StateInterferenceMap from "../components/StateInterferenceMap"

export default function MapTest({  }) {

  return (
    <Layout>
      <Seo/>
      <StateInterferenceMap />
      <Footer />
    </Layout>
  )
}
