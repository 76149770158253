import "./index.scss"
import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import selectSocialIcon from "../Icons/selectSocialIcon"
import EAFormEmbed from "../EAFormEmbed"

export default function Footer({ showFooterForm }) {
  // = = = = = = = = = = = = =
  // GraphQL
  const { footerData } = useStaticQuery(graphql`
    query FooterQuery {
      footerData: allDatoCmsFooter {
        nodes {
          bottomNav {
            links {
              isThisAButton
              label
              linkUrl
            }
          }
          footerText
          socialMedia {
            socialMediaItem {
              socialMediaId
              socialMediaLink
            }
          }
          twoColumnNav {
            links {
              linkUrl
              label
              isThisAButton
            }
          }
          everyActionFormLink
          footerLogo {
            url
          }
        }
      }
    }
  `)

  const {
    bottomNav,
    footerText,
    socialMedia,
    twoColumnNav,
    footerLogo,
    everyActionFormLink,
  } = footerData.nodes[0]

  return (
    <footer
      data-datocms-noindex
      className={`footer ${showFooterForm && "hasForm"}`}
    >
      <div className="container footer__content">
        <div className="row">
          <div className="col-md-3">
            <div className="site-logo">
              <Link className="site-logo__link" to="/">
                <img className="site-logo__image" src={footerLogo?.url} />
              </Link>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-3">
            <div
              className="footer__text"
              dangerouslySetInnerHTML={{ __html: footerText }}
            />
          </div>

          {twoColumnNav?.map((nav, index) => (
            <div
              key={index}
              className={`col-md-2 ${
                showFooterForm && index === 0 ? "offset-md-1" : null
              } ${!showFooterForm && index === 0 ? "offset-md-5" : null}`}
            >
              <div className="nav--footer">
                {nav.links?.map(link => (
                  <Link
                    key={link.label}
                    className="nav--footer__links"
                    to={link.linkUrl}
                  >
                    {link.label}
                  </Link>
                ))}
              </div>
            </div>
          ))}
        </div>

        <div className="row">
          <div className="col-md-4">
            <div className="social-media">
              {socialMedia[0]?.socialMediaItem?.map(media => (
                <a
                  key={media.socialMediaId}
                  className="social-media__link social-media__link--footer"
                  href={media.socialMediaLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  {selectSocialIcon(media.socialMediaId, "white")}
                </a>
              ))}
            </div>
          </div>

          <div className={`col-md-4 ${showFooterForm ? null : "offset-md-4"}`}>
            <div className="nav--bottom">
              {bottomNav[0]?.links?.map(link => (
                <Link
                  key={link.label}
                  className="nav--bottom__links"
                  to={link.linkUrl}
                >
                  {link.label}
                </Link>
              ))}
            </div>
          </div>
        </div>

        <div className="row made-by-row">
          <div className="col-md-12">
            <p>
              Copyright {new Date().getFullYear()} © PowerSwitch Action |{" "}
              <a href="https://www.tectonica.co">
                Made by <b>Tectonica</b>
              </a>
            </p>
          </div>
        </div>
      </div>
      {showFooterForm && (
        <div className="footerForm">
          <EAFormEmbed formLink={everyActionFormLink} />
        </div>
      )}
    </footer>
  )
}
